// components/Footer.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // If using react-router for navigation
import PopupComponent from './Popup';
import logoIcon from '../assets/images/logo.svg';
import telegramIcon from '../assets/images/footer__telegram.svg';
import whatsIcon from '../assets/images/footer__whatsup.svg';
import vkIcon from '../assets/images/footer__vk.svg';
import { useCity } from '../contexts/CityContext';

const Footer = () => {
  const { getPhoneNumber, getNormalizedPhoneNumber, getEmail } = useCity();
  const phoneNumber = getPhoneNumber();
  const normalizedPhoneNumber = getNormalizedPhoneNumber();
  const email = getEmail();

  const [setMessage] = useState('');

  const notifyResult = (isSuccess) => {
        if (isSuccess) {
        setMessage('Данные успешно отправлены!');
        } else {
        setMessage('Ошибка при отправке данных.');
        }
  };

  return (
      <footer className="footer">
        <div className="flex flex-col sm:flex-row justify-between text-white padding-default">
          <div className="gap-3 flex flex-col items-center content-center sm:items-start">
            <img className="w-[150px] sm:w-auto sm:h-[52px] sm:mb-[0px]" src={logoIcon} alt="Logo" />
            <Link className="hidden sm:block" to="/privacy">Политика конфиденциальности</Link>
            <Link className="text-center" to="/">@ 2014-2024 Мебельная компания "Том"</Link>
          </div>
          <div className="content-center hidden sm:gap-3 sm:flex sm:flex-col ">
            <Link className="font-montserrat-semibold" to="/">Компания "Том"</Link>
            <Link className="" to="/about">О нас</Link>
            <Link className="" to="/designers">Сотрудничество</Link>
            <Link className="" to="/contacts">Как нас найти</Link>
          </div>
          <div className="hidden sm:gap-3 sm:flex sm:flex-col">
            <Link className="font-montserrat-semibold " to="/catalog">Каталог</Link>
            <Link className="" to="/catalog/tables">Столы</Link>
            <Link className="" to="/catalog/chairs">Стулья</Link>
            <Link className="" to="/catalog/sofas">Диваны</Link>
            <Link className="" to="/catalog/armchairs">Кресла</Link>
            <Link className="" to="/catalog/coffee_tables">Журнальные Столики</Link>
            <Link className="" to="/catalog/furnishings">Предметы Интерьера</Link>
          </div>
          <div className="hidden sm:gap-3 sm:flex sm:flex-col">
            <Link className="font-montserrat-semibold" to="/delivery_payment">Доставка и оплата</Link>
            <Link className="" to="/delivery_payment">Доставка</Link>
            <Link className="" to="/delivery_payment">Оплата</Link>
            <Link className="" to="/delivery_payment">Возврат</Link>
          </div>
          <div className="flex flex-col text-center gap-3 sm:text-right">
            <a className="" href={`tel:${normalizedPhoneNumber}`}>{phoneNumber}</a>
            <a className="" href={`mailto:${email}`}>{email}</a>
            {/* footer logo container */}
            <div className="flex flex-row gap-3 justify-center sm:justify-end">
              <a className="" href="">
                <img className="w-5 h-5" src={telegramIcon} alt="" />
              </a>
              <a className="" href="">
                <img className="w-5 h-5" src={whatsIcon} alt="" />
              </a>
              <a className="" href="">
                <img className="w-5 h-5" src={vkIcon} alt="" />
              </a>
            </div>
            <PopupComponent
                buttonText="Связаться с нами"
                className="bttn p-2"
                notifyResult={notifyResult} />
          </div>
        </div>
      </footer>
  );
};

export default Footer;
