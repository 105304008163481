// src/components/Favorites.js
import React, { useContext, useState } from 'react';
import { useCity } from '../contexts/CityContext';
import BaseLayout from './BaseLayout';
import FavoritesContext from '../contexts/FavoritesContext';
import ProductCard from './ProductCard';
import Nav from './Nav.js';

const Favorites = () => {
  const { selectedCity } = useCity();
  const navigationItems = {
        '': 'Главная', 
        'favorite': 'Избранное', 
  };

  const categoryMapping = {
    1: 'chairs',
    3: 'tables',
    4: 'furnishings',
    5: 'armchairs',
    6: 'coffee_tables',
    7: 'sofas',
  };

  const { favorites } = useContext(FavoritesContext);

  // Get unique categories from favorite items
  const categories = [...new Set(favorites.map(product => product.Category.Name))];
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredFavorites, setFilteredFavorites] = useState(favorites); // Start by showing all favorites

  // Handle category checkbox selection
  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((cat) => cat !== category) // Remove if already selected
        : [...prevSelected, category] // Add if not selected
    );
  };

  // Apply filter based on selected categories
  const applyFilter = () => {
    if (selectedCategories.length === 0) {
      setFilteredFavorites(favorites); // Show all if no category is selected
    } else {
      setFilteredFavorites(favorites.filter((product) => selectedCategories.includes(product.Category.Name)));
    }
  };

  return (
    <BaseLayout>
      <main className="font-montserrat-regular padding-default pb-5">
        {/* aside nav */}
        <Nav items={navigationItems} />
        
        {/* h for pages */}
        <h2 className="h2__aside pb-[34px] sm:pb-[40px]">Избранное</h2>

        <div className="flex flex-col pb-30 sm:flex-row sm:gap-5">
          {/* Category Filter */}
          <div className="filter-goods sm:h-[70%]">
              <h3 className="font-montserrat-medium">Категория</h3>
            {categories.map((category, index) => (
              <label key={index} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                  className="form-checkbox"
                />
                {category}
              </label>
            ))}
            
            <button
              onClick={applyFilter}
              className="bttn sm:py-[10px] px-[36px]"
            >
              Применить
            </button>
          </div>
        {/* Display Filtered Products */}
        {filteredFavorites.length > 0 ? (
          <div className="flex flex-row flex-wrap gap-5">
            {filteredFavorites.map((product) => {
              const categoryName = categoryMapping[product.CategoryID] || 'default';
              return (
              <ProductCard key={product.MoyskladID}
                           selectedCity={selectedCity} 
                           product={product}
                           category={categoryName} />
              );
            })}
          </div>
        ) : (
          <p className="">Товары не найдены</p>
        )}
    </div>
      </main>
    </BaseLayout>
  );
};

export default Favorites;
