import React, { useEffect } from 'react';
import telegramBlackIcon from '../assets/images/telegram_black.svg';
import instaBlackIcon from '../assets/images/insta_black.svg';
import whatsupBlackIcon from '../assets/images/whatsup_black.svg';
import { useCity } from '../contexts/CityContext';


const MapComponent = () => {

  const { getPhoneNumber, getNormalizedPhoneNumber, getEmail, getAddress, getMapId, getMapData } = useCity();
  const phoneNumber = getPhoneNumber();
  const normalizedPhoneNumber = getNormalizedPhoneNumber();
  const email = getEmail();
  const address = getAddress();
  const mapId = getMapId();
  const mapData = getMapData();
  const time = "10:00 - 20:00";


  const contactDetails = {
    tel: phoneNumber,
    normTel: normalizedPhoneNumber,
    mail: email,
    address: address,
    mapId: mapId,
    mapData: mapData,
  };


    useEffect(() => {
        const iframe = document.getElementById(contactDetails.mapId);
        if (iframe) {
            const contentWindow = iframe.contentWindow;
            contentWindow.document.open();
            contentWindow.document.write(atob(contactDetails.mapData));
            contentWindow.document.close();
        }
    }, [contactDetails.mapId, contactDetails.mapData]);

    return (
        <div className="flex flex-col pb-20 sm:bg-none sm:flex-row">
          <div className="flex-1 text-14 pr-10 bg-back
                          sm:flex-none sm:w-1/2
                          md:text-16 md:pr-[40px]
                          lg:text-18 lg:pr-[50px]
                          xl:text-18 xl:pr-[60px]">
            <div className="pb-10">
              {/* Contact information */}
              <div className="pb-[18px] sm:hidden">
                <span className="font-semibold">Телефон: </span>
                <a className="" href={`tel:${contactDetails.normTel}`}>
                  {contactDetails.tel}
                </a>
              </div>
              <div className="pb-[18px] sm:hidden">
                <span className="font-semibold">Эл. почта: </span>
                <a className="" href={`mailto:${contactDetails.mail}`}>
                  {contactDetails.mail}
                </a>
              </div>
              <div className="pb-[18px] flex flex-row flex-start sm:hidden">
                <a href={`tel:${contactDetails.tel}`}>
                  <img className="w-[25px] h-[25px] mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-5" src={telegramBlackIcon} alt="Telegram" />
                </a>
                <a href={`tel:${contactDetails.tel}`}>
                  <img className="w-[25px] h-[25px] mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-5" src={instaBlackIcon} alt="Instagram" />
                </a>
                <a href={`tel:${contactDetails.tel}`}>
                  <img className="w-[25px] h-[25px] mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-5" src={whatsupBlackIcon} alt="WhatsApp" />
                </a>
              </div>
              {/* Address section */}
              <div>
                <h3 className="hidden sm:block sm:pt-[50px] sm:pb-8 font-montserrat-semibold">Салон на карте</h3>
                <h3 className="sm:hidden pb-5 font-montserrat-semibold">Адреса магазинов</h3>
                {/* First address */}
                <div className="flex flex-col mb-5
                                sm:mb-[40px] sm:border-default sm:justify-between sm:flex-row sm:px-[32px] sm:py-5">
                  <p className="sm:text-[16px] sm:w-3/4">
                    {contactDetails.address.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                  <div className="sm:text-[#a0a0a0] sm:text-[16px] sm:flex sm:flex-col sm:justify-between">
                    <p className="hidden sm:block">
                      {time}
                    </p>
                    <a className="hidden sm:block " href={`tel:${contactDetails.normTel}`}>
                      {contactDetails.tel}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-1/2">
            <iframe
                className="w-[100%] h-[520px] "
                id={mapId}
                sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
                frameBorder="0"
                title="2GIS Map"
            ></iframe>
          </div>
      </div>
    );
};

export default MapComponent;

