// components/Header.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // If using react-router for navigation
import FavoritesContext from '../contexts/FavoritesContext';
import CartContext from '../contexts/CartContext';
import favorite from '../assets/images/favorite.svg';
import favoriteMobile from '../assets/images/favorite_black.svg';
import search from '../assets/images/loupe.svg';
import searchMobile from '../assets/images/loupeMobile.svg';
import cart from '../assets/images/cart.svg';
import cartMobile from '../assets/images/cart_mobile.svg';
import favoriteMobileActive from '../assets/images/favorite_filled.svg'
import telephoneIcon from '../assets/images/telephone.svg';
import logoIcon from '../assets/images/logo.svg';
import logoMobile from '../assets/images/logo_mobile.svg';
import CitySelection from './CitySelection';
import { useCity } from '../contexts/CityContext';


const Header = () => {
    const navigate = useNavigate(); // Hook to navigate between pages
    const location = useLocation();
    const { favoritesCount } = useContext(FavoritesContext);
    const { getCartCount } = useContext(CartContext); // Access cart count
    const { getPhoneNumber, getNormalizedPhoneNumber, handleCityChange } = useCity();
    const phoneNumber = getPhoneNumber();
    const normalizedPhoneNumber = getNormalizedPhoneNumber();
    const cartCount = getCartCount(); // Get the cart count
    const [searchTerm, setSearchTerm] = useState(''); // Store user search input
    const isFull = ['/', '/about'].includes(location.pathname);

  // Handle search submission (navigate to search results page)
  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting traditionally
    navigate(`/search?query=${searchTerm}`); // Navigate to the search results page
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Close the menu if clicked outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Check if the click is outside of the menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close the menu
            }
        };

        // Add event listener to detect outside clicks
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        // Cleanup event listener on unmount or when the menu closes
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isMenuOpen]);

  return (
    <header
        className="header w-full h-auto">
        <div className="header__top">
          <div className="flex flex-col">
          <div className="flex-1 flex flex-row items-center justify-between">
            <div className="sm:hidden">
              {/* Burger Menu Button */}
              <div className="mr-[12px] relative">
                {/* Burger Icon */}
                <button onClick={toggleMenu} className="text-black focus:outline-none">
                  <svg className="w-[25px h-[25px]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                </button>
                {/* Side Menu */}
                <div ref={menuRef} className={`border-defalut border-r-[1px] fixed top-0 left-0 w-64 h-full bg-white text-black z-50 transition-transform transform ${
              isMenuOpen ? 'translate-x-0' : '-translate-x-full'
            }`}>
                  <div className="p-4">
                    <ul className="mt-4">
                      <li><Link to="/" className="block py-2 px-4 ">Главная</Link></li>
                      {/* Expandable Section */}
                      <li>
                        <details className="group">
                          <summary className="py-2 px-4 cursor-pointer">
                            Каталог
                            <svg className="inline w-4 h-4 ml-2 transition-transform group-open:rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                          </summary>
                          <ul className="pl-4">
                            <li><Link to="/catalog/tables/" className="block py-2 px-4">Столы</Link></li>
                            <li><Link to="/catalog/chairs/" className="block py-2 px-4">Стулья</Link></li>
                            <li><Link to="/catalog/sofas/" className="block py-2 px-4">Диваны</Link></li>
                            <li><Link to="/catalog/armchairs/" className="block py-2 px-4">Кресла</Link></li>
                            <li><Link to="/catalog/coffee_tables/" className="block py-2 px-4">Журнальные столики</Link></li>
                            <li><Link to="/catalog/furnishings/" className="block py-2 px-4">Предметы интерьера</Link></li>
                          </ul>
                        </details>
                      </li>
                      <li><Link to="/delivery_payment" className="block py-2 px-4 ">Доставка и оплата</Link></li>
                      <li><Link to="/contacts" className="block py-2 px-4 ">Контакты</Link></li>
                      <li><Link to="/designers" className="block py-2 px-4 ">Дизайнерам</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Telephone */}
            <div className="flex flex-row items-center hidden sm:flex sm:items-center">
              <a className="sm:w-[24px] sm:h-[24px] sm:mr-[10px] xl:mr-[12px] sm:self-start mt-[10px]" href={`tel:${normalizedPhoneNumber}`}>
                <img className="" src={telephoneIcon} alt="Telephone" />
              </a>
              <div className="mr-[10px]">
                <a className="text-[16px] py-[2px]" href={`tel:${normalizedPhoneNumber}`}>{phoneNumber}</a>
                <p className="text-[11px] py-[1px]">Ответим на ваши вопросы 24\7</p>
              </div>
              {/* CitySelection */}
              <div className="hidden sm:block">
                <CitySelection onCityChange={handleCityChange} />
              </div>
            </div>

            {/* Logo */}
            <div className="block sm:hidden mr-[12px]">
              <Link to="/">
                <img className="w-[120px] h-[80px]" src={logoMobile} alt="Logo" />
              </Link>
            </div>
            <div className="hidden sm:block">
              <Link to="/">
                <img className="w-[160px] h-[60px] md:w-[280px] md:h-[75px]" src={logoIcon} alt="Logo" />
              </Link>
            </div>

            {/* Search, favorite, cart */}
            <div className="flex flex-row content-center justify-between">
              {/* Search */}
              <div className="search">
                <form onSubmit={handleSearchSubmit} className="flex flex-row items-center mx-[2px]">
                    <button type="w-[22px] h-[22px] submit">
                      <img className="sm:hidden" src={searchMobile} alt="search" />
                      <img className="hidden sm:block" src={search} alt="search" />
                    </button>
                  <input
                    type="text"
                    className="w-full sm:block h-[26px] sm:pl-2 pr-4 py-2 focus:ring-0 focus:border-transparent focus:outline-none sm:text-white bg-transparent placeholder-gray-300"
                    placeholder="Поиск"
                    value={searchTerm} // Bind input to searchTerm state
                    onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
                  />
                </form>
              </div>
              {/* Favorite Mobile */}
              <div className="mr-[14px] flex flex-row items-center sm:hidden">
                <Link className="w-[24px] h-[24px] mr-[6px]" to="/favorite">
                    <img className=""
                         src={favoritesCount > 0 ? favoriteMobileActive : favoriteMobile} alt="Favorite"/>
                </Link>
                <span className="text-black">{favoritesCount}</span>
              </div>
              {/* Favorite */}
              <div className="hidden sm:flex sm:self-center sm:mr-[22px] md:mr-[26px] lg:mr-[28px] xl:mr-[30]">
                <Link className="w-[24px] h-[24px] mr-[6px]" to="/favorite">
                    <img className="" src={favorite} alt="Favorite" />
                </Link>
                <span className="">{favoritesCount}</span>
              </div>
              {/* Cart Mobile*/}
              <div className="flex flex-row items-center sm:hidden">
                <Link className="w-[24px] h-[24px] sm:mr-[6px]" to="/cart">
                  <img className="" src={cartMobile} alt="Cart" />
                </Link>
                <span className="text-black">{cartCount}</span>
              </div>
              {/* Cart */}
              <div className="hidden sm:flex sm:self-center">
                <Link className="w-[24px] h-[24px] sm:mr-[6px]" to="/cart">
                  <img className="" src={cart} alt="Cart" />
                </Link>
                <span className="">{cartCount}</span>
              </div>
            </div>
          </div>
          <div className="sm:hidden">
            <CitySelection onCityChange={handleCityChange} />
          </div>
    </div>
        </div>
    {/* header middle */}
        <div className="header__middle">
          <Link className="" to="/about"> О нас </Link>
          <Link className="" to="/catalog"> Каталог </Link>
          <Link className="" to="/delivery_payment"> Доставка и оплата </Link>
          <Link className="" to="/designers"> Дизайнерам </Link>
          <Link className="" to="/contacts"> Контакты </Link>
        </div>
		{/* header bottom */}
{isFull &&
        <div className="header__bottom">
            <h1 className="font-montserrat-medium text-[18px] pb-[26px] w-[265px]
						  sm:text-[26px] sm:pb-[42px] sm:w-[376px]
						  md:text-[34px] md:pb-[60px] md:w-[487px]
						  lg:text-[42px] lg:pb-[80px] lg:w-[598px]
						  xl:pb-[80px] xl:w-[600px]">
                “ТОМ” - МАГАЗИН ДИЗАЙНЕРСКОЙ МЕБЕЛИ
            </h1>
            <p className="font-montserrat-regular text-[12px] w-[196px]
              sm:text-[16px]
              md:text-[20px]
              lg:text-[24px] lg:w-[425px]">
                Надежный поставщик стильной и функциональной мебели
            </p>
        </div>
}
	</header>
	)
}

export default Header;
