// src/components/CoffeeTables.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseLayout from './BaseLayout';
import ProductCard from './ProductCard';
import { useCity } from '../contexts/CityContext';
import UnifiedFilter from './UnifiedFilter';
import StyleFilter from './StyleFilter';
import SortDropdown from './SortDropdown';
import Nav from './Nav.js';

const CoffeeTables = () => {
    const navigationItems = {
        '': 'Главная', 
        'catalog': 'Каталог', 
        'coffeetables': 'Журнальные столики', 
    };
    const CATEGORY_ID = 6;
    const { selectedCity } = useCity();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [styles, setStyles] = useState([]);
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [pendingSelectedFilters, setPendingSelectedFilters] = useState({});
    const [setSortOption] = useState('');
    const [priceRange, setPriceRange] = useState({ from: '', to: '' }); // Add price range state

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${CATEGORY_ID}`, {
                    params: { city: selectedCity },
                });
                const productsData = response.data;
                setProducts(productsData);
                setFilteredProducts(productsData);

                // Extract unique style and other filter options relevant to chairs
                const filterOptions = extractFilterOptions(productsData);
                setStyles(filterOptions.styles);
                setFilters(filterOptions.otherFilters);

            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchProducts();
    }, [selectedCity]);

    // Helper function to extract filter options from products
    const extractFilterOptions = (products) => {
        const stylesSet = new Set();
        
        // Only include these characteristics in filters for chairs
        const relevantCharacteristics = new Set(['Ткань', 'Цвет ноги']);
        const otherFilters = {
            'Ткань': new Set(),
            'Цвет ноги': new Set(),
        };

        products.forEach(product => {
            product.Modification.forEach(modification => {
                modification.ModificationCharacteristics.forEach(characteristic => {
                    if (characteristic.Name === 'Стиль') {
                        stylesSet.add(characteristic.Value);
                    } else if (relevantCharacteristics.has(characteristic.Name)) {
                        otherFilters[characteristic.Name].add(characteristic.Value);
                    }
                });
            });
        });

        return {
            styles: Array.from(stylesSet),
            otherFilters: Object.keys(otherFilters).map(name => ({
                name,
                values: Array.from(otherFilters[name]),
            })),
        };
    };

    // Function to apply sorting
    const applySort = (products, option) => {
        switch (option) {
            case 'popular':
                return [...products].sort((a, b) => b.Popular - a.Popular); // Assuming Popular is a number
            case 'newest':
                return [...products].sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));
            case 'priceAsc':
                return [...products].sort((a, b) => a.Price - b.Price);
            case 'priceDesc':
                return [...products].sort((a, b) => b.Price - a.Price);
            case 'discountAsc':
                return [...products].sort((a, b) => a.Discount - b.Discount);
            default:
                return products;
        }
    };

    const handleSortChange = (option) => {
        setSortOption(option);
        setFilteredProducts(applySort(filteredProducts, option));
    };

    const handleStyleChange = (style) => {
        const updatedStyles = selectedStyles.includes(style)
            ? selectedStyles.filter(s => s !== style)
            : [...selectedStyles, style];
        setSelectedStyles(updatedStyles);
        applyFilters(updatedStyles, selectedFilters); // Immediate filter application for styles
    };

    const handleFilterChange = (filterName, value) => {
        setPendingSelectedFilters(prevFilters => {
            const updatedFilterValues = prevFilters[filterName]?.includes(value)
                ? prevFilters[filterName].filter(v => v !== value)
                : [...(prevFilters[filterName] || []), value];

            return { ...prevFilters, [filterName]: updatedFilterValues };
        });
    };

    const handlePriceRangeChange = (field, value) => {
        setPriceRange((prevRange) => ({ ...prevRange, [field]: value }));
    };

    const applyFilters = (styles = selectedStyles, filters = pendingSelectedFilters) => {
        let results = products;

        // Filter by styles
        if (styles.length > 0) {
            results = results.filter(product =>
                product.Modification.some(modification =>
                    modification.ModificationCharacteristics.some(char =>
                        char.Name === 'Стиль' && styles.includes(char.Value)
                    )
                )
            );
        }

        // Apply checkbox filters
        for (const filterName in filters) {
            if (filters[filterName].length > 0) {
                results = results.filter(product =>
                    product.Modification.some(modification =>
                        modification.ModificationCharacteristics.some(char =>
                            char.Name === filterName && filters[filterName].includes(char.Value)
                        )
                    )
                );
            }
        }

        // Apply price filter
        if (priceRange.from || priceRange.to) {
            results = results.filter(product => {
                const price = product.Price;
                const isAboveMin = priceRange.from ? price >= parseFloat(priceRange.from) : true;
                const isBelowMax = priceRange.to ? price <= parseFloat(priceRange.to) : true;
                return isAboveMin && isBelowMax;
            });
        }

        setFilteredProducts(results);
    };

    const applyPendingFilters = () => {
        setSelectedFilters(pendingSelectedFilters);
        applyFilters(selectedStyles, pendingSelectedFilters);
    };

    return (
        <BaseLayout>
            <main className="font-montserrat-regular padding-default">
                <Nav items={navigationItems} />
                <h2 className="h-default">Журнальные столики</h2>

                <StyleFilter styles={styles} selectedStyles={selectedStyles} onStyleChange={handleStyleChange} />


                <div className="flex flex-col pb-30 sm:flex-row sm:gap-5 mb-[30px]">
                  <div className="w-[18%] h-[70%] flex flex-col pb-30 sm:flex-row sm:gap-5">
                      <UnifiedFilter
                          filters={filters}
                          selectedFilters={pendingSelectedFilters}
                          onFilterChange={handleFilterChange}
                          onApplyFilters={applyPendingFilters}
                          priceRange={priceRange}
                          onPriceRangeChange={handlePriceRangeChange}
                      />
                  </div>
                  <div className="w-full flex flex-col gap-3">
                          <SortDropdown onSortChange={handleSortChange} />
                          <div className="flex flex-row flex-wrap gap-5">
                              {filteredProducts
                                  .map(product => (
                                  <ProductCard key={product.MoyskladID} selectedCity={selectedCity} product={product} category={"chairs"} />
                              ))}
                          </div>
                  </div>
                </div>
            </main>
        </BaseLayout>
    );
}

export default CoffeeTables;
