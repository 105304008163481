// src/components/Designers.js
import React, { useState } from 'react';
import BaseLayout from './BaseLayout';
import sideBack from '../assets/images/side_first.jpg';
import sideMobile from '../assets/images/side_back.png';
import Popup from './Popup';
import Nav from './Nav.js';



const Designers = () => {
    const navigationItems = {
        '': 'Главная', 
        'designers': 'Дизайнерам', 
    };

    const designers = "Дизайнерам"

    const [message, setMessage] = useState('');

    const notifyResult = (isSuccess) => {
        if (isSuccess) {
        setMessage('Данные успешно отправлены!');
        } else {
        setMessage('Ошибка при отправке данных.');
        }
    };


  return (
    <BaseLayout>
            <main className="font-montserrat-regular padding-default">
                {/* aside nav */}
                <Nav items={navigationItems} />
                <div className="
                    rounded border-0 border-solid color-footer flex flex-col pb-20 bg-right-bottom
                    sm:pb-0 sm:bg-none sm:flex-row"
                    style={{ backgroundImage: `url(${sideMobile})`}}>
                    <div className="
                        flex-1 text-14 pr-10 bg-back
                        md:text-16 md:pr-12
                        lg:text-18 lg:pr-20
                        xl:text-18 xl:pr-36">
                        {/* h for pages */}
                        <h2 className=" h2__aside pb-[10px] sm:pb-[50px]">
                            {designers}
                        </h2>
                        <div className="pb-[40px]">
                            <p>
                                Если вы заинтересованы в сотрудничестве с нами и хотите стать нашим официальным дилером в вашем регионе, пожалуйста, свяжитесь с нами для обсуждения деталей.
                                Мы готовы обсудить ваши требования и предложить вам взаимовыгодные условия, которые сделают наше сотрудничество успешным и долгосрочным.
                            </p>
                        </div>
                      <Popup buttonText="Оставить заявку"
                            className="bttn py-2.5 px-5 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9"
                            notifyResult={notifyResult} />
                      {message && <p className={`message ${message.includes('успешно') ? 'success' : 'error'}`}>{message}</p>}
                    </div>
                    <div className="
                        hidden flex-1 p-0
                        sm:flex
                    ">
                        <img className="w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]" src={ sideBack }
                             alt="Дизайнерам" />
                    </div>
                </div>
            </main>
    </BaseLayout>
  );
};

export default Designers;
