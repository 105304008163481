//
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import axios from "axios";
import BaseLayout from "./BaseLayout";
import CartButton from "./CartButton";
import FavoriteButton from "./FavoriteButton";
import Popup from "./Popup";
import ProductImageSlider from "./ProductImageSlider.js";
import { useCity } from '../contexts/CityContext';

const ExtendedProductCard = () => {
  const { selectedCity } = useCity();
  const { category, moysklad_id: MoyskladID } = useParams();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // Always define hooks at the top level
  const [selectedModification, setSelectedModification] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  console.log(MoyskladID)
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        if (location.state?.product) {
          setProduct(location.state.product);
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/products/product/${MoyskladID}`,
            { params: { city: selectedCity } },
          );
          if (response.data.length > 0) {
            setProduct(response.data[0]);
        } else {
          console.error("Product not found in API response");
        }
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [MoyskladID, location.state]);

  // Update hooks when the product changes
  useEffect(() => {
    if (product) {
      const initialModification = product.Modification?.[0] || {};
      setSelectedModification(initialModification);
      setCurrentImages(
        initialModification?.ModificationImages?.length
          ? initialModification.ModificationImages
          : product.ProductImages || []
      );
    }
  }, [product]);

  if (loading) {
    return (
      <BaseLayout>
        <main className="font-montserrat-regular px-20 pb-20 text-default">
          <p>Loading...</p>
        </main>
      </BaseLayout>
    );
  }

  if (!product) {
    return (
      <BaseLayout>
        <main className="font-montserrat-regular px-20 pb-20 text-default">
          <p>Product not found</p>
        </main>
      </BaseLayout>
    );
  }

  const handleModificationClick = (modification) => {
    setSelectedModification(modification);
    setCurrentImages(
      modification.ModificationImages?.length
        ? modification.ModificationImages
        : product.ProductImages || []
    );
  };

  return (
    <BaseLayout>
      <main className="font-montserrat-regular px-20 pb-20 text-default">
        {/* Navigation breadcrumb */}
        <aside className="aside-default">
          <Link to="/">Главная / </Link>
          <Link to="/catalog">Каталог / </Link>
          <Link to={`/catalog/${category}`}>
            {product.Category?.Name || "Категория"} /{" "}
          </Link>
          <span>{product.Name}</span>
        </aside>

        <div className="flex flex-col border-default sm:gap-3 sm:border-none sm:flex-row">
          <div className="card-page sm:border-default">
            <div className="mb-[24px] sm:mb-[10px] flex flex-row justify-between sm:justify-end">
              <div className="text-center sm:hidden">{product.Name}</div>
              <FavoriteButton product={product} />
            </div>

            <div className="items-center h-full">
              {currentImages.length > 0 ? (
                <ProductImageSlider
                  initialProduct={product}
                  selectedModification={selectedModification}
                />
              ) : (
                <p className="text-center">Изображение отсутствует</p>
              )}
            </div>
          </div>

          <div className="card-page sm:border-default">
            <button className="hidden text-left text-[20px] md:text-[22px] lg:text-[24px] sm:block">
              {product.Name}
            </button>

            {product.Modification?.length > 0 && (
              <div className="flex flex-row flex-wrap gap-[20px]">
                {product.Modification.map((modification, index) => (
                  <img
                    key={index}
                    className={`rounded-[5px] w-[75px] h-[70px] cursor-pointer ${
                      selectedModification === modification
                        ? "border-[2px] border-blue-500"
                        : "border-[1px] border-[#A3ACA3]"
                    }`}
                    src={`${process.env.REACT_APP_API_URL}/${modification.ModificationImages?.[0]?.ImageURL || ""}`}
                    alt={`Img ${index + 1}`}
                    onClick={() => handleModificationClick(modification, index)}
                  />
                ))}
              </div>
            )}

            <div className="sm:flex-row flex flex-col justify-between">
              <div className="text-[20px] md:text-[22px] lg:text-[24px]">
                {selectedModification?.SalePrices} ₽
              </div>
              <div className="text-[20px] md:text-[22px] lg:text-[24px]">
                {selectedModification?.Stock ? (
                  `${selectedModification?.Stock} шт. в наличии`
                ) : (
                  <span className="text-[20px] sm:text-[22px] lg:text-[24px] text-red-500">Под заказ</span>
                )}
              </div>
            </div>
            <CartButton
              product={product}
              isOutOfStock={selectedModification?.Stock === 0}
            />

            <div className="text-[14px] sm:text-[18px] flex flex-col gap-5 sm:gap-[24px]">
              {selectedModification?.ModificationCharacteristics?.length > 0 ? (
                selectedModification.ModificationCharacteristics.map(
                  (characteristic, index) => (
                    <div className="flex flex-row" key={index}>
                      <span className="w-[150px] font-montserrat-semibold">
                        {characteristic.Name}
                      </span>
                      <span>{characteristic.Value}</span>
                    </div>
                  )
                )
              ) : (
                <p>Нет доступных вариантов</p>
              )}
              <p className="pt-[20px] border-[#A3ACA3] border-t-[1px] border-solid">
                Товар доступен для заказа в других цветах и материалах
              </p>
              <Popup
                buttonText="Индивидуальный заказ"
                className="btn-mod border-mod"
              />
              <p className="pt-[20px] border-[#A3ACA3] border-t-[1px] border-solid text-[10px] sm:text-[12px]">
                * Окончательная стоимость доставки рассчитывается индивидуально,
                в зависимости от удаленности
              </p>
            </div>
          </div>
        </div>
      </main>
    </BaseLayout>
  );
};

export default ExtendedProductCard;
// import React, { useState, useEffect, useMemo } from "react";
// import { useLocation, useParams, Link } from "react-router-dom";
// import axios from "axios";
// import BaseLayout from "./BaseLayout";
// import CartButton from "./CartButton";
// import FavoriteButton from "./FavoriteButton";
// import Popup from "./Popup";
// import ProductImageSlider from "./ProductImageSlider.js";
//
// const ExtendedProductCard = ({ product }) => {
//   const { category, Moysklad } = useParams();
//   const location = useLocation();
//   const [product, setProduct] = useState(null);
//   const [loading, setLoading] = useState(true);
//
//   const initialProduct = useMemo(() => {
//     return location.state?.product || product || {};
//   }, [location.state?.product, product]);
//
//   // Initialize selectedModification and currentImages without conditional hook calls
//   const [selectedModification, setSelectedModification] = useState(
//     initialProduct.Modification?.[0] || {}
//   );
//
//   const [currentImages, setCurrentImages] = useState(
//     selectedModification?.ModificationImages?.length
//       ? selectedModification.ModificationImages
//       : initialProduct.ProductImages || [] // Ensure currentImages has a default array
//   );
//
//   const [selectedModificationIndex, setSelectedModificationIndex] = useState(0);
//
//   useEffect(() => {
//     setCurrentImages(
//       selectedModification?.ModificationImages?.length
//         ? selectedModification.ModificationImages
//         : initialProduct.ProductImages || []
//     );
//   }, [selectedModification, initialProduct]);
//
//   const handleModificationClick = (modification, index) => {
//     setSelectedModification(modification);
//     setSelectedModificationIndex(index);
//   };
//
//   // If there's no initial product, display a "Product not found" message in the render
//   return (
//     <BaseLayout>
//       <main className="font-montserrat-regular px-20 pb-20 text-default">
//         {/* Navigation breadcrumb */}
//         <aside className="aside-default">
//           <Link to="/">Главная / </Link>
//           <Link to="/catalog">Каталог / </Link>
//           <Link to={`/catalog/${category}`}>
//             {initialProduct.Category?.Name || "Категория"} /{" "}
//           </Link>
//           <span>{initialProduct.Name}</span>
//         </aside>
//
//         {initialProduct.Name ? (
//           <div className="flex flex-col border-default sm:gap-3 sm:border-none sm:flex-row">
//             {/* Product Card Image and Details */}
//             <div className="card-page sm:border-default">
//               <div className="mb-[24px] sm:mb-[10px] flex flex-row justify-between sm:justify-end">
//                 <div className="text-center sm:hidden">{initialProduct.Name}</div>
//                 <FavoriteButton product={initialProduct} />
//               </div>
//
//               <div className="items-center h-full">
//                 {/* Only render ProductImageSlider if there are images */}
//                 {currentImages.length > 0 ? (
//                   <ProductImageSlider
//                     initialProduct={initialProduct}
//                     selectedModification={selectedModification}
//                   />
//                 ) : (
//                   <p className="text-center">Изображение отсутствует</p>
//                 )}
//               </div>
//             </div>
//
//             {/* Modification Details */}
//             <div className="card-page sm:border-default">
//               <button
//                 className="hidden text-left text-[20px] md:text-[22px] lg:text-[24px] sm:block"
//               >
//                 {initialProduct.Name}
//               </button>
//
//               {/* Mini images for modifications */}
//               {initialProduct.Modification?.length > 0 && (
//                 <div className="flex flex-row flex-wrap gap-[20px]">
//                   {initialProduct.Modification.map((modification, index) => (
//                     <img
//                       key={index}
//                       className={`rounded-[5px] w-[75px] h-[70px] cursor-pointer ${
//                         selectedModificationIndex === index
//                           ? "border-[2px] border-blue-500" // Active state
//                           : "border-[1px] border-[#A3ACA3]" // Default state
//                       }`}
//                       src={`${process.env.REACT_APP_API_URL}/${modification.ModificationImages?.[0]?.ImageURL || ""}`}
//                       alt={`Img ${index + 1}`}
//                       onClick={() => handleModificationClick(modification, index)}
//                     />
//                   ))}
//                 </div>
//               )}
//
//               {/* Price and Stock for the selected modification */}
//               <div className="sm:flex-row flex flex-col justify-between">
//                 <div className="text-[20px] md:text-[22px] lg:text-[24px]">
//                   {selectedModification?.SalePrices} ₽
//                 </div>
//                 <div className="text-[20px] md:text-[22px] lg:text-[24px]">
//                   {selectedModification?.Stock ? (
//                     `${selectedModification?.Stock} шт. в наличии`
//                   ) : (
//                     <span className="text-[20px] sm:text-[22px] lg:text-[24px] text-red-500">Под заказ</span>
//                   )}
//                 </div>
//               </div>
//               {/* Add To Cart */}
//               <CartButton
//                 product={initialProduct}
//                 isOutOfStock={selectedModification.Stock === 0}
//               />
//
//               {/* Characteristics for the selected modification */}
//               <div className="text-[14px] sm:text-[18px] flex flex-col gap-5 sm:gap-[24px]">
//                 {selectedModification?.ModificationCharacteristics?.length > 0 ? (
//                   selectedModification.ModificationCharacteristics.map(
//                     (characteristic, index) => (
//                       <div className="flex flex-row" key={index}>
//                         <span className="w-[150px] font-montserrat-semibold">
//                           {characteristic.Name}
//                         </span>
//                         <span>{characteristic.Value}</span>
//                       </div>
//                     )
//                   )
//                 ) : (
//                   <p>Нет доступных вариантов</p>
//                 )}
//                 <p className="pt-[20px] border-[#A3ACA3] border-t-[1px] border-solid">
//                   Товар доступен для заказа в других цветах и материалах
//                 </p>
//                 <Popup
//                   buttonText="Индивидуальный заказ"
//                   className="btn-mod border-mod"
//                 />
//                 <p className="pt-[20px] border-[#A3ACA3] border-t-[1px] border-solid text-[10px] sm:text-[12px]">
//                   * Окончательная стоимость доставки рассчитывается индивидуально,
//                   в зависимости от удаленности
//                 </p>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div>Товар не найден</div>
//         )}
//       </main>
//     </BaseLayout>
//   );
// };
//
// export default ExtendedProductCard;
//
