import React from 'react';

const UnifiedFilter = ({
  filters,
  selectedFilters,
  onFilterChange,
  onApplyFilters,
  priceRange,
  onPriceRangeChange
}) => {
  return (
    <div className="filter-goods">
      {/* Price Range Filter */}
      <div className="flex flex-col gap-[6px]">
        <h3 className="font-montserrat-medium">Цена</h3>
        <div className="flex flex-row gap-[20px]">
          <input
            type="number"
            placeholder="От"
            min="0"
            value={priceRange.from}
            onChange={(e) => onPriceRangeChange('from', e.target.value)}
            className="w-[40%] border mr-2 p-1"
          />
          <input
            type="number"
            placeholder="До"
            min="1"
            value={priceRange.to}
            onChange={(e) => onPriceRangeChange('to', e.target.value)}
            className="w-[40%] border p-1"
          />
        </div>
      </div>

      {/* Other Filters */}
      {filters.map((filter) => (
        <div key={filter.name} className="filter-goods__group">
          <h3 className="font-montserrat-medium">{filter.name}</h3>
          {filter.values.map((value) => (
            <label key={value}>
              <input
                className="mr-2"
                type="checkbox"
                checked={selectedFilters[filter.name]?.includes(value) || false}
                onChange={() => onFilterChange(filter.name, value)}
              />
              {value}
            </label>
          ))}
        </div>
      ))}
      <button className="bttn" onClick={onApplyFilters}>Применить</button> {/* Trigger apply filters */}
    </div>
  );
}

export default UnifiedFilter;

